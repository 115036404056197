import { FC, useRef, useEffect } from 'react';
import BookCardItem from '../book-card-item/BookCardItem';
import styles from './BookCardsHiddenList.module.scss';
import { IBooksPageData } from '@/interfaces/booksPage.interface';

interface IBookCardsHiddenListProps {
  books: IBooksPageData[];
  extraClassName?: string;
}

const BookCardsHiddenList: FC<IBookCardsHiddenListProps> = ({ books, extraClassName }) => {
  const getListClassNames = () => (extraClassName ? `${styles.bookCardsList} ${styles[`${extraClassName}`]}` : `${styles.bookCardsList}`);
  const cardsListContent = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const bookCards = cardsListContent.current?.querySelectorAll('[data-card="hidden"]');

    if (bookCards !== undefined && bookCards?.length > 0) {
      const SHOW_STEP = 10;
      const showMoreBtn = cardsListContent.current?.querySelector('[data-btn-show-more="cards"]');
      let showCounter = SHOW_STEP;

      const showMoreCards = () => {
        for (let i = 0; i <= showCounter - 1; i++) {
          if ((bookCards[i] as HTMLDivElement).dataset.card === 'hidden') {
            (bookCards[i] as HTMLDivElement).dataset.card = 'show';
          }
        }

        showCounter += SHOW_STEP;

        if (bookCards.length === showCounter) {
          showMoreBtn?.remove();
        }
      };

      showMoreBtn?.addEventListener('click', showMoreCards);
    }
  });

  return (
    <div ref={cardsListContent} data-cards="list">
      <div className={getListClassNames()}>
        {books.length ? (
          books.map((book: IBooksPageData, i) => (
            <>
              {i <= 9 ? (
                <BookCardItem type={book.type} freeVersion={!!book.allow_full_free && book.allow_full_free > 0} id={book.id} rating={book.rating} key={book.id} author={book.author} book={book.book} dataName="show" />
              ) : (
                <BookCardItem type={book.type} freeVersion={!!book.allow_full_free && book.allow_full_free > 0} id={book.id} rating={book.rating} key={book.id} author={book.author} book={book.book} dataName="hidden" />
              )}
            </>
          ))
        ) : (
          <div>Книги не найдены</div>
        )}
      </div>

      <div className={styles.moreCardsBtn} data-btn-show-more="cards">
        <span className={styles.moreCardsBtn__txt}>Показать еще</span>
      </div>
    </div>
  );
};

export default BookCardsHiddenList;
